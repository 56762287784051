import http from '@/shared/http'
import router from '@/router'
import employeeService from '@/shared/services/employeeService'
import { dcBaseURL, seniorBaseURL } from '@/shared/constants/api.routes'
import { NavigationRoutes } from '@/shared/constants/navigation.routes'
import User from '@/shared/models/User'

export default {
  setInterceptors({ dispatch }) {
    // response
    http.interceptors.response.use(undefined, (error) => {
      if (error.response.status === 401) {
        dispatch('login', true)
      }

      return Promise.reject(error)
    })
  },

  async checkAuthenticated({ state, getters, dispatch }) {
    const { authenticated } = getters

    if (!authenticated) {
      dispatch('login', true)
      return
    }

    http.defaults.headers.common['Authorization'] = state.token

    await dispatch('loadUser')

    if (!getters.userValidated) {
      dispatch('login', true)
      return
    }
  },

  login({ dispatch }, resetUidAndToken = false) {
    if (resetUidAndToken) {
      dispatch('setUid')
      dispatch('setToken')
    }

    const { auth: authRoute } = NavigationRoutes.general

    if (router.app.$route.name === authRoute.name) {
      return
    }

    const qp = window.location.search

    router.push(authRoute.path + qp)
  },

  setUid({ commit }, uid) {
    if (uid === undefined) {
      commit('setUid', undefined)
      return
    }

    commit('setUid', uid && uid.length === 7 ? uid : null)
  },

  setDoubleFactor({ commit }, isActivated) {
    commit('setDoubleFactor', isActivated)
  },

  setToken({ commit }, token) {
    commit('setToken', token)

    if (token) {
      http.defaults.headers.common['Authorization'] = token
    } else {
      delete http.defaults.headers.common['Authorization']
    }
  },

  async loadUser({ state, commit, getters, dispatch }) {
    if (!state.uid || state.uid.length != 7) {
      return
    }

    const personalData = await employeeService.getPersonalData()
    commit('setUser', new User(state.uid, personalData))

    // prevent dc users from logging into dc portal and vice versa
    const isForbidden =
      (getters.isDc && http.defaults.baseURL != dcBaseURL) ||
      (getters.isSenior && http.defaults.baseURL != seniorBaseURL)
    commit('setForbidden', isForbidden)

    if (isForbidden) {
      await dispatch('resetState')
      return
    }

    http.defaults.baseURL = getters.isDc ? dcBaseURL : seniorBaseURL

    const personalDataForm = await employeeService.getPersonalDataForm()
    commit('setName', personalDataForm.seniorDatosS1VO.nombre)

    await dispatch('checkIfWidow', personalDataForm)
    await dispatch('portal/loadHome', undefined, { root: true })
    if (getters.isSenior) await dispatch('checkIfRetired')
  },

  async checkIfWidow({ commit }, personalDataForm) {
    const isWidow = personalDataForm.seniorDatosS1VO.viudo
    commit('setWidow', isWidow)
  },

  async checkIfRetired({ commit }) {
    const response = await employeeService.checkIfRetired()
    const isRetired = response.literalMensaje === 'true'
    commit('setRetired', isRetired)
  },

  async resetState({ commit, dispatch }) {
    await dispatch('setUid')
    await dispatch('setToken')
    await dispatch('setDoubleFactor')
    commit('setUser')
  },

  async closeSession({ dispatch }) {
    await dispatch('resetState')
    await dispatch('portal/resetState', undefined, { root: true })
  },
}
